<template>
  <div>
    <register-form-component />
  </div>
</template>

<script>
import RegisterFormComponent from "@/views/Register/components/RegisterFormComponent.vue";

export default {
  components: { RegisterFormComponent },
};
</script>

<style lang="scss" scoped></style>
